import { httpsCallable, HttpsCallable } from "firebase/functions";
import { useCallback, useMemo, useState } from "react";

import useFunctionsService from "./useFunctionsService";
import { useSnackbar } from "../hooks/useSnackBar";

const useContactSupport = () => {
  const { functions } = useFunctionsService();
  const { addSnack } = useSnackbar();
  const [isSendingContactInfo, setIsSendingContactInfo] = useState(false);

  const getContactInfoForm: HttpsCallable<
    {
      title: string;
      additionalComments: string;
      supportEmail?: string;
    },
    any
  > = useMemo(
    () => httpsCallable(functions, "unlockreturns-contactSupport"),
    [functions]
  );

  const getContactInformationForm = useCallback(
    async (body: {
      title: string;
      additionalComments: string;
      supportEmail?: string;
    }) => {
      try {
        setIsSendingContactInfo(true);
        const result = await getContactInfoForm({
          title: body.title,
          additionalComments: body.additionalComments,
          supportEmail: body.supportEmail,
        });

        if (result.data.statusCode === 200) {
          addSnack({
            display: true,
            severity: "success",
            horizontal: "right",
            vertical: "top",
            message: "Message sent correctly",
            className: "createLabelSnackBar",
          });
          return true;
        } else {
          addSnack({
            display: true,
            severity: "error",
            horizontal: "right",
            vertical: "top",
            message: "Unexpected error while processing sending message.",
            className: "createLabelSnackBar",
          });
        }
        return result.data;
      } catch (e) {
        addSnack({
          display: true,
          severity: "error",
          horizontal: "right",
          vertical: "top",
          message: "Unexpected error while sending message.",
          className: "createLabelSnackBar",
        });
        console.error(e);
        throw new Error(
          "Unexpected error while authenticating, try again later!"
        );
      } finally {
        setIsSendingContactInfo(false);
      }
    },
    [getContactInfoForm, addSnack]
  );
  return { getContactInformationForm, isSendingContactInfo };
};

export default useContactSupport;
