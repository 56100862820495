import { httpsCallable, HttpsCallable } from "firebase/functions";
import { useCallback, useMemo } from "react";

import useFunctionsService from "./useFunctionsService";

import { ReturnReasonValue } from "../components/Common/ReturnReasonSelect";
// import useAuth from "../hooks/useAuth";
import { ProductForm } from "../pages/ReturnsPage/steps/Step2/index";

type Item = {
  item: string;
  quantity: string;
  location: string;
  custcol_ucp_return_comments: string;
  custcol_ucp_return_reason: string;
  custcol_ucp_return_location_line: string;
  custcol_ucp_return_item_eligibility: string;
  custcol_ucp_return_date: string;
  custcol_ucp_return_option: string;
  custcol_ucp_qty_returned: string;
};

// Define the request body type here
type RMARequestBody = {
  autoApproveSetting?: boolean;
  returnReason: Record<string, ReturnReasonValue>;
  productForm: ProductForm[];
  itemKey: string;
  domain: string;
  fullUrl: string;
  customerId: string;
  itemList: Item[];
  orderNumber: string;
  additionalComments?: string;
  refoundMethod?: string;
  handlingFee?: string;
  custrecord_ucp_commerce_platform?: string;
  custrecord_ucp_order_number_field_id?: string;
  custrecord_ucp_handling_fee_item?: string;
  emailFrom: string;
  emailTo: string;
  name?: string;
  email?: string;
};
const usePostRMAInformation = () => {
  const { functions } = useFunctionsService();
  // TODO: use Token from useAuth
  // const { credential } = useAuth();
  // const token = useMemo(() => credential?.token, [credential]);

  const postRMAInformationFunction: HttpsCallable<
    RMARequestBody,
    {
      result: any;
    }
  > = useMemo(
    () => httpsCallable(functions, "unlockreturns-postRMAInformation"),
    [functions]
  );

  const postRMAInformation = useCallback(
    async (rmaRequestBody: RMARequestBody) => {
      try {
        const postRMAInformation = await postRMAInformationFunction({
          ...rmaRequestBody,
        });
        const newRMAInformation = postRMAInformation.data.result;
        return newRMAInformation;
      } catch (e) {
        console.error(e);
        throw new Error(
          "Unexpected error while processing the return, try again later!"
        );
      }
    },
    [postRMAInformationFunction]
  );

  return { postRMAInformation };
};

export default usePostRMAInformation;
