import { httpsCallable, HttpsCallable } from "firebase/functions";
import { useCallback, useMemo, useState } from "react";

import useFunctionsService from "./useFunctionsService";
import { useSnackbar } from "../hooks/useSnackBar";

// import useAuth from "../hooks/useAuth";
// Define the request body type here

type RequestBody = {
  from_address: any;
  parcel: any;
  rmaId: string;
  addressId: string;
  domain: string;
  custrecord_ucp_return_auth_form: string;
  custrecord_ucp_shipping_base_url?: string;
};

const useCreateLabelInfo = () => {
  const { functions } = useFunctionsService();
  const { addSnack } = useSnackbar();
  const [isLabelInfoCreating, setIsLabelInfoCreating] = useState(false);
  // TODO: use Token from useAuth
  // const { credential } = useAuth();
  // const token = useMemo(() => credential?.token, [credential]);

  const createLabelInfoFunction: HttpsCallable<
    RequestBody,
    {
      statusCode: number;
      message: string;
      result: any;
    }
  > = useMemo(
    () => httpsCallable(functions, "unlockreturns-getLabelInfo"),
    // () => httpsCallable(functions, "unlockreturns-getShiphawkInfo"),
    [functions]
  );

  const createLabelInfo = useCallback(
    async (requestBody: RequestBody) => {
      try {
        setIsLabelInfoCreating(true);
        addSnack({
          display: true,
          severity: "info",
          horizontal: "right",
          vertical: "top",
          message: "Creating PDF Label...",
          className: "createLabelSnackBar",
        });
        const createLabelInfoResponse = await createLabelInfoFunction({
          ...requestBody,
        });
        if (createLabelInfoResponse.data.statusCode === 500) {
          const errorMessage = createLabelInfoResponse.data.message;
          throw new Error(errorMessage);
        }
        addSnack({
          display: true,
          severity: "success",
          horizontal: "right",
          vertical: "top",
          message: "Label created successfully",
          className: "createLabelSnackBar",
        });
        const labelInfo = createLabelInfoResponse.data.result;
        return labelInfo;
      } catch (e) {
        console.error(e);
        setIsLabelInfoCreating(false);
        addSnack({
          display: true,
          severity: "error",
          horizontal: "right",
          vertical: "top",
          message: "Unexpected error while processing creating label.",
          className: "createLabelSnackBar",
        });
        throw new Error("Unexpected error while processing creating label");
      } finally {
        setIsLabelInfoCreating(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createLabelInfoFunction]
  );

  return { createLabelInfo, isLabelInfoCreating };
};

export default useCreateLabelInfo;
