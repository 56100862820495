import { useState, useContext, useEffect } from "react";
import { OrderContext } from "../../providers/OrderProvider";
import useReturnReasonService from "../../services/useReturnReasonService";
import useGetInvoiceInformation from "../../services/useGetInvoiceInformation";
import { isEmpty } from "lodash";

import { ReturnReasonsOptions } from "../../entities/ReturnReasons";
import { Steps } from "../../enums/Steps";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";

import classes from "./ReturnsPage.module.scss";
import classNames from "classnames";

import LayoutSteps from "../../components/Common/LayoutSteps";
import SubNavbar from "../../components/Returns/SubNavbar";
import Title from "../../components/Common/Title";
import Paragraph from "../../components/Common/Paragraph";
import LoadSpinner from "../../components/Common/LoadSpinner";
import ReturnStatusHeader from "../../components/Returns/ReturnStatusHeader";
import { Status } from "../../enums/Status";
import useGetParams from "../../hooks/useGetParams";

const ReturnsPage = () => {
  const { handleSetCurrentRma, currentRma, currentOrders } =
    useContext(OrderContext);
  const { handleSetCurrentInvoiceData } = useContext(OrderContext);
  const { data } = useGetInvoiceInformation();
  const { getReturnReason } = useReturnReasonService();
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(Steps.STEP1);
  const { orderNumber, zipCode } = useGetParams();

  const [currentStatus, setCurrentStatus] = useState(Status.CreationProcess);
  const [returnReasonsOptions, setReturnReasonsOptions] = useState<
    ReturnReasonsOptions[]
  >([]);

  const [step, setStep] = useState(
    sessionStorage?.getItem("step")
      ? (sessionStorage?.getItem("step") as Steps)
      : Steps.STEP1
  );

  useEffect(() => {
    if (Object.values(Steps).includes(step)) {
      setCurrentStep(step);
    }
  }, [step]);

  /*   useEffect(() => {
    const previousUrl = window.localStorage.getItem("previousUrl");
    const currentUrl = location.pathname + location.search;
    if (previousUrl !== currentUrl) {
      handleClearSessionStorage();
      setStep(Steps.STEP3);
    }
    window.localStorage.setItem("previousUrl", currentUrl);
  }, [handleClearSessionStorage, location, step]); */

  const hasEligibleProduct = currentOrders.some(
    (product) => product.eligible === true
  );

  const handleSetStep = (step: Steps) => {
    sessionStorage.setItem("step", step);
    setStep(step);
  };

  console.log("isLoading :>> ", isLoading);

  useEffect(() => {
    if (sessionStorage.getItem("currentRma") && step === Steps.STEP3) {
      const arr: any = JSON.parse(sessionStorage.getItem("currentRma") ?? "");
      const rmaList: any = JSON.parse(sessionStorage.getItem("rmaList") ?? "");

      console.log(
        'arr?.status === "pendingCreation" :>> ',
        arr?.status === "pendingCreation"
      );

      console.log(
        "data?.rmaInfo?.results?.length === arr.length :>> ",
        // @ts-ignore
        data?.rmaInfo?.results?.length === arr.length
      );

      if (
        arr?.status === "pendingCreation" &&
        data?.rmaInfo &&
        // @ts-ignore
        data?.rmaInfo?.results?.length === rmaList + 1
      ) {
        setIsLoading(true);
        // @ts-ignore
        handleSetCurrentRma(data?.rmaInfo);
        setIsLoading(false);
      }
    }

    if (step === Steps.STEP2 || step === Steps.STEP3) {
      return;
    }
    setIsLoading(true);
    if (data?.invoice) {
      handleSetCurrentInvoiceData(data);
      setIsLoading(false);
    }

    if (data?.rmaInfo) {
      // @ts-ignore
      handleSetCurrentRma(data?.rmaInfo);
      setIsLoading(false);
    }
  }, [
    // @ts-ignore eslint-disable-next-line
    data?.rmaInfo?.results?.length,
  ]);

  useEffect(() => {
    if (step === Steps.STEP2 || step === Steps.STEP3) {
      return;
    }
    if (!isEmpty(data?.rmaInfo?.results)) {
      handleSetStep(Steps.STEP3);
    } else {
      handleSetStep(Steps.STEP1);
    }
    // @ts-ignore
  }, [data?.rmaInfo?.results?.length]);

  /*   useEffect(() => {
    const fetchData = async () => {

      if (currentStatus === Status.PendingCreation) {
        await delay(2000);
      }

      let errorMessage = "";
      try {
        if (zipCode && orderNumber) {
          setIsLoading(true);
          const response = await getStatusOrderForm({
            zipCode,
            orderNumber,
            domain: getHostname(),
          });

          console.log("rmaInfo", rmaInfo);
          // @ts-ignore
          console.log("rmaInfo", response.rmaInfo?.results?.[0]?.status);
          if (
            // @ts-ignore
            rmaInfo === response.rmaInfo?.results?.[0]?.status
          ) {
            setIsLoading(false);
            return;
          }
          await delay(500);
          setIsLoading(true);

          if (response.invoice) {
            handleSetCurrentInvoiceData(response);
            if (!isEmpty(response.rmaInfo)) {
              // @ts-ignore
              setRmaInfo(response.rmaInfo?.results?.[0]?.status);
              // @ts-ignore
              handleSetCurrentRma(response.rmaInfo);
            }
            if (!isEmpty(response.rmaInfo.results)) {
              handleSetStep(Steps.STEP3);
            } else {
              handleSetStep(Steps.STEP1);
            }
          } else {
            navigate(Path.Home);
            if (response.statusCode === 404 || response.statusCode === 500) {
              errorMessage = `Order Number: ${orderNumber} with Zip Code: ${zipCode} not found`;
            } else if (response.statusCode === 409) {
              errorMessage = response?.message;
            } else {
              errorMessage = response?.message;
            }
            addSnack({
              display: true,
              severity: "error",
              message: errorMessage,
            });
          }
        }
      } catch (error) {
        console.error(error);
        navigate(Path.Home);
        errorMessage = `Order Number: ${orderNumber} with Zip Code: ${zipCode} not found`;
        addSnack({
          display: true,
          severity: "error",
          message: errorMessage,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getStatusOrderForm,
    orderNumber,
    zipCode,
    rmaInfo,
    currentStatus === Status.PendingCreation,
    // @ts-ignore
    data?.rmaInfo?.results?.[0]?.status,
  ]); */

  useEffect(() => {
    const fetchReturnReason = async () => {
      const response = await getReturnReason();
      setReturnReasonsOptions(
        response?.map((reason) => ({
          label: reason.name,
          value: reason,
        })) ?? []
      );
    };
    fetchReturnReason();
  }, [getReturnReason]);

  const getCurrentStep = (handleSetStep: (step: Steps) => void) => ({
    [Steps.STEP1]: (
      <Step1 handleSetStep={handleSetStep} isLoading={isLoading} />
    ),
    [Steps.STEP2]: (
      <Step2
        handleSetStep={handleSetStep}
        returnReasonsOptions={returnReasonsOptions}
        setCurrentStatus={setCurrentStatus}
      />
    ),
    [Steps.STEP3]: (
      <Step3
        handleSetStep={handleSetStep}
        zipCode={zipCode ?? ""}
        currentStatus={currentStatus}
      />
    ),
  });

  const status = currentRma?.status || "default";

  return (
    <>
      <LayoutSteps>
        {!hasEligibleProduct && currentStep !== Steps.STEP3 && !isLoading ? (
          <>
            <header
              className={classNames(
                classes.stepHeader,
                classes.noItemsEligible
              )}
            >
              <Title kind="h1" size="l">
                Whoops! No items are eligible for return.
              </Title>
              <Paragraph size="xxs">
                We are sorry but no items in your order are eligible for a
                return.
              </Paragraph>
              <Paragraph size="xxs">
                Per our Terms and Conditions, returns must be returned within 30
                days of purchase delivery.
              </Paragraph>
              <Paragraph size="xxs">
                If you need further assistance, please contact our customer
                service team.
              </Paragraph>
            </header>
          </>
        ) : (
          <>
            <ReturnStatusHeader
              status={status}
              step={step}
              handleSetStep={handleSetStep}
              currentStep={currentStep}
            />

            <SubNavbar step={step} handleSetStep={handleSetStep} />
          </>
        )}
        {isLoading && <LoadSpinner />}
        <section className={classes.steps}>
          {getCurrentStep(handleSetStep)[step]}
        </section>
      </LayoutSteps>
    </>
  );
};

export default ReturnsPage;
