import { Customer, Invoice, Item, Summary } from "../entities/InvoiceInfo";
import useGetParams from "./useGetParams";
import { useState, useEffect } from "react";

const useInvoiceData = () => {
  const { orderNumber } = useGetParams();

  const [customerInfo, setCustomerInfo] = useState<Customer | null>(null);
  const [summaryInfo, setSummaryInfo] = useState<Summary | null>(null);
  const [discountInfo, setDiscountInfo] = useState<number>(0);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [nonAssociatedDiscountItems, setNonAssociatedDiscountItems] =
    useState<Array<Item> | null>(null);

  useEffect(() => {
    const storedCustomerInfo = sessionStorage.getItem("customerInfo");
    if (storedCustomerInfo) {
      setCustomerInfo(JSON.parse(storedCustomerInfo));
    } else {
      setCustomerInfo(null);
    }

    const storedSummaryInfo = sessionStorage.getItem("summaryInfo");
    if (storedSummaryInfo) {
      setSummaryInfo(JSON.parse(storedSummaryInfo));
    } else {
      setSummaryInfo(null);
    }

    const storedDiscountInfo = sessionStorage.getItem("discountInfo");
    if (storedDiscountInfo) {
      setDiscountInfo(JSON.parse(storedDiscountInfo));
    } else {
      setDiscountInfo(0);
    }

    const storedInvoice = sessionStorage.getItem("invoice");
    if (storedInvoice) {
      setInvoice(JSON.parse(storedInvoice));
    } else {
      setInvoice(null);
    }

    const storedNonAssociatedDiscountItems = sessionStorage.getItem(
      "nonAssociatedDiscountItems"
    );
    if (storedNonAssociatedDiscountItems) {
      setNonAssociatedDiscountItems(
        JSON.parse(storedNonAssociatedDiscountItems)
      );
    } else {
      setNonAssociatedDiscountItems(null);
    }
  }, [orderNumber]);

  return {
    customerInfo,
    summaryInfo,
    discountInfo,
    invoice,
    nonAssociatedDiscountItems,
  };
};

export default useInvoiceData;
