import { useMediaQuery } from "react-responsive";
import Cookies from "js-cookie";
import classes from "./ReturnProduct.module.scss";
import classNames from "classnames";

import Row from "../../../components/Common/Row";
import Column from "../../../components/Common/Column";
import Title from "../../../components/Common/Title";
import Paragraph from "../../../components/Common/Paragraph";
import Image from "../../../components/Common/Image";
import QuantityInput from "../../../components/Common/QuantityInput";
import ReturnReasonSelect from "../../../components/Common/ReturnReasonSelect";
import Textarea from "../../../components/Common/Textarea";
import ItemDetailsAccordion from "../ItemDetailsAccordion";
import { Order } from "../../../entities/Order";
import { Steps } from "../../../enums/Steps";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import {
  OrderContext,
  generateBaseImageUrl,
} from "../../../providers/OrderProvider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { itemKey } from "../../../utils/item-utils";
import { formatPrice } from "../../../utils/formatter-utils";
import useGetParams from "../../../hooks/useGetParams";

const ReturnProduct = ({
  product,
  ...productFormProps
}: {
  product: any;
  [key: string]: any;
}) => {
  const {
    quantities,
    returnReason,
    notes,
    handleQuantityChange,
    handleReturnReasonsChange,
    handleNotesChange,
    returnReasonError,
    notesError,
    labelOptions,
    isNotesMandatory,
  } = productFormProps.props || {};

  const { selectedProductsToReturn, handleSetSelectedProductsToReturn } =
    useContext(OrderContext);
  const [currentStep, setCurrentStep] = useState(Steps.STEP1);
  const [step] = useState(
    sessionStorage?.getItem("step")
      ? (sessionStorage?.getItem("step") as Steps)
      : Steps.STEP1
  );
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    false
  );

  useEffect(() => {
    if (Object.values(Steps).includes(step)) {
      setCurrentStep(step);
    }
  }, [step]);

  const onSelectProduct = (
    e: ChangeEvent<HTMLInputElement>,
    product: Order
  ) => {
    if (e.target.checked) {
      const newProducts = [...selectedProductsToReturn, product];
      handleSetSelectedProductsToReturn(newProducts);
    } else {
      const newProducts = selectedProductsToReturn.filter(
        (selectedProduct) => selectedProduct?.[itemKey] !== product?.[itemKey]
      );
      handleSetSelectedProductsToReturn(newProducts);
    }
  };

  const isChecked = (product: Order) => {
    return selectedProductsToReturn.some(
      (selectedProduct) => selectedProduct?.[itemKey] === product?.[itemKey]
    );
  };

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  const toggleAccordionIcon = (productId: string) => {
    if (expandedAccordion === productId) {
      return <FontAwesomeIcon icon={faMinus} />;
    } else {
      return <FontAwesomeIcon icon={faPlus} />;
    }
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenL})`,
  });

  const netSuiteAccountId = Cookies.get("application")
    ? JSON.parse(String(Cookies.get("application"))).netSuiteAccountId
    : "";

  const getPrice = () =>
    product?.discount === 0
      ? Number(Math.abs(product?.rate * product?.quantity))
      : Number(
          Math.abs(product?.rate * product?.quantity) -
            Math.abs(product?.discount * product?.quantity)
        );

  const calculateDiscountPercentage = () => {
    const originalPrice = Math.abs(product?.rate * product?.quantity);
    const discountedPrice =
      originalPrice - Math.abs(product?.discount * product?.quantity);
    const discountPercentage =
      ((originalPrice - discountedPrice) / originalPrice) * 100;
    const discountString = discountPercentage.toFixed(2);
    return discountString.endsWith("00") ? discountPercentage : discountString;
  };

  return (
    <div
      className={classNames(
        classes.product,
        `${isChecked(product) && step === Steps.STEP1 && classes.checked}`
      )}
      key={product?.[itemKey]}
    >
      <div className={classes.productContainer}>
        <div className={classes.productImg}>
          <Image
            src={
              currentStep === Steps.STEP1 || currentStep === Steps.STEP2
                ? product?.img
                : generateBaseImageUrl(
                    String(netSuiteAccountId),
                    product.itemThumbnail,
                    product.isCustomImage
                  )
            }
            alt={product.itemName}
          />
        </div>

        <div className={classes.productContent}>
          <Title kind="h3" size="xs">
            <>
              {product?.line && (
                <>
                  <span className={classes.lineCode}>#{product?.line}</span>{" "}
                </>
              )}
              {product?.name}
            </>
          </Title>
          {!isMobile && (
            <>
              <Paragraph size="xxs" className={classes.quantity}>
                <span>Quantity:&nbsp;</span>
                {Math.abs(product?.quantity)}
              </Paragraph>
              <div className={classes.priceWrapper}>
                <Paragraph size="xxs" className={classes.price}>
                  $&nbsp;{getPrice()}
                </Paragraph>
                {product?.discount !== 0 && (
                  <Paragraph size="xxs" className={classes.originalPrice}>
                    $&nbsp;
                    {formatPrice(Math.abs(product?.rate * product?.quantity))}
                  </Paragraph>
                )}
                {product?.discount !== 0 && (
                  <span className={classes.discount}>
                    {product?.discountName}&nbsp;{calculateDiscountPercentage()}
                    %
                  </span>
                )}
              </div>
            </>
          )}
        </div>
        {/* Checkbox on step 1 for eligible product */}
        {/* TODO: add partial logic */}
        {currentStep === Steps.STEP1 &&
          product.eligible &&
          product.quantityReturned === 0 && (
            /* product.quantityReturned === 0 &&  */ <div
              className={classes.productCheckboxWrapper}
            >
              <Checkbox
                className={classes.productCheckbox}
                checked={isChecked(product)}
                onChange={(e) => onSelectProduct(e, product)}
              />
            </div>
          )}
      </div>

      {currentStep === Steps.STEP1 && product.quantityReturned > 0 && (
        <div
          className={classNames(
            classes.returnAlreadyExists,
            `${isChecked(product) && step === Steps.STEP1 && classes.checked}`
          )}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className={classes.icon}
          />
          <Paragraph size="xxxs">
            A return already exists for this item
          </Paragraph>
        </div>
      )}

      {isMobile && (
        <ItemDetailsAccordion
          product={product}
          currentStep={currentStep}
          key={product?.[itemKey]}
        />
      )}
      {/* Step 2 Product Form */}
      {currentStep === Steps.STEP2 && (
        <div className={classes.returnProduct}>
          <Row className={classes.returnProductRow}>
            <Column width={50}>
              <QuantityInput
                id={`quantity_${product?.[itemKey]}`}
                name={`quantity_${product?.[itemKey]}`}
                value={product.quantity}
                label="Quantity"
                onValueChange={handleQuantityChange}
                productId={parseInt(product?.[itemKey])}
                quantity={quantities[product?.[itemKey]]}
                max={product.quantity}
              />
            </Column>
            <Column width={50}>
              <ReturnReasonSelect
                label="Return Reason*"
                id={`reason_${product?.[itemKey]}`}
                name={`reason_${product?.[itemKey]}`}
                options={labelOptions.map(
                  (option: { label: any; value: any }) => ({
                    label: option.label,
                    value: option.value,
                  })
                )}
                onChange={handleReturnReasonsChange}
                className={classNames(
                  classes.returnSelect,
                  `${
                    returnReasonError && !returnReason[product?.[itemKey]]
                      ? classes.error
                      : ""
                  }`
                )}
              />
              {isMobile && (
                <small className={classes.stepError}>{returnReasonError}</small>
              )}
            </Column>
          </Row>
          <Textarea
            label={`Notes${isNotesMandatory ? "*" : ""}`}
            id={`notes_${product?.[itemKey]}`}
            name={`notes_${product?.[itemKey]}`}
            onChange={handleNotesChange}
            className={classNames(
              classes.notesTextarea,
              `${notesError && !notes[product?.[itemKey]] ? classes.error : ""}`
            )}
          />
          {isMobile && (
            <small className={classes.stepError}>{notesError}</small>
          )}
        </div>
      )}
      {/* Step 3 Return Details Accordion */}
      {currentStep === Steps.STEP3 && (
        <div className={classes.itemReturnDetails}>
          <Accordion
            sx={{
              boxShadow: "none",
              border: "1px solid #E5E7EB",
              marginTop: "1rem !important",
              fontSize: ".75rem",
              fontWeight: "600",
              borderRadius: "4px",
            }}
            onChange={handleAccordionChange(product?.[itemKey])}
          >
            {!isMobile && (
              <AccordionSummary
                sx={{
                  height: "56px",
                  minHeight: "auto !important",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "4px",
                  "&.Mui-expanded": {
                    backgroundColor: "#E5E7EB",
                  },
                }}
                className={
                  expandedAccordion === product?.[itemKey]
                    ? classes.summaryExpanded
                    : ""
                }
              >
                <div className={classes.returnDetailsAccordionHeader}>
                  <span>Return Details</span>
                  <div className={classes.toggleIcon}>
                    {toggleAccordionIcon(product?.[itemKey])}
                  </div>
                </div>
              </AccordionSummary>
            )}
            <AccordionDetails
              sx={{
                padding: "1rem",
                backgroundColor: "#F3F4F6",
              }}
            >
              <div className={classes.returnDetailsContent}>
                <div className={classes.returnDetailsTable}>
                  <div className={classes.returnDetailsTableRow}>
                    <span>Return Reason:&nbsp;</span>
                    <span>{product?.custcol_ucp_return_reason}</span>
                  </div>
                  <div className={classes.returnDetailsTableRow}>
                    <span>Quantity:&nbsp;</span>
                    <span>{Math.abs(product?.custcol_ucp_qty_returned)}</span>
                  </div>
                </div>
                <div className={classes.returnDetailsTable}>
                  <div
                    className={classNames(
                      classes.returnDetailsTableRow,
                      classes.returnDetailsNotes
                    )}
                  >
                    <span>Additional Notes:</span>
                    <span>{product?.custcol_ucp_return_comments}</span>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default ReturnProduct;
