import { useSearchParams } from "react-router-dom";

const useGetParams = () => {
  const [searchParams] = useSearchParams();

  const orderNumber =
    sessionStorage.getItem("orderNumber") ?? searchParams.get("order-number");

  const zipCode =
    sessionStorage.getItem("zipCode") ?? searchParams.get("zip-code");

  return { zipCode, orderNumber };
};

export default useGetParams;
