import { httpsCallable, HttpsCallable } from "firebase/functions";
import useSWR from "swr";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import useFunctionsService from "./useFunctionsService";
import useAuth from "../hooks/useAuth";
import { RMADataResults } from "../entities/RMAData";

type FetchRMAInformationParams = {
  token?: string;
  orderNumber: string;
  zipCode: string;
  domain: string;
};

const useGetRMAInformation = () => {
  const { functions } = useFunctionsService();
  const { credential } = useAuth();
  const token = useMemo(() => credential?.token, [credential]);

  const [searchParams] = useSearchParams();
  const orderNumber =
    sessionStorage?.getItem("orderNumber") ?? searchParams.get("order-number");
  const zipCode =
    sessionStorage?.getItem("zipCode") ?? searchParams.get("zip-code");
  const domain = useMemo(() => window.location.hostname, []);

  const getRMAInformationFormFunction: HttpsCallable<
    FetchRMAInformationParams,
    RMADataResults
  > = useMemo(
    () => httpsCallable(functions, "unlockreturns-getRMAInformation"),
    [functions]
  );

  const getRMAInformationForm = useCallback(
    async (statusOrderForm: FetchRMAInformationParams) => {
      try {
        const result = await getRMAInformationFormFunction({
          token,
          ...statusOrderForm,
        });
        return result.data;
      } catch (e) {
        console.error(e);
        throw new Error(
          "Unexpected error while authenticating, try again later!"
        );
      }
    },
    [token, getRMAInformationFormFunction]
  );

  const { data, error, isValidating, mutate } = useSWR<any>(
    token && orderNumber && zipCode && domain
      ? [orderNumber, zipCode, domain]
      : null,
    ([orderNumber, zipCode, domain]) =>
      getRMAInformationForm({
        token,
        orderNumber,
        zipCode: String(zipCode),
        domain: String(domain),
      }),
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  return {
    data,
    error,
    isValidating,
    mutate,
    getRMAInformationForm, // Exponemos la función manual
  };
};

export default useGetRMAInformation;
